import { api } from "../../../api/axios-base";

export const fetchTotalIncomeByStudent = async (studentId, firstDate, endDate, institutionId) => {
  try {
    const response = await api.post('/app-administracion/helpers/get-ingreso-por-alumno', {
      id_alumno: studentId,
      fecha_inicio: firstDate,//this.dateRange.from,
      fecha_fin: endDate,//this.dateRange.to,
      id_institucion_educativa: institutionId//this.institutionId
    })
    const responseData = await response.data
    return responseData.resultado.total_ingreso__sum
  } catch (error) {
    console.error('Error al obtener total de ingreso al mes por alumno', error);
  }
};
export const fetchTotalExpenseByEmployee = async (
  employeeId,
  fistDate,
  endDate,
  institutionId
) => {
  try {
    const response = await api.post(
      `/app-administracion/helpers/get-egresos-empleado`,
      {
        id_empleado: employeeId,
        fecha_inicio: fistDate,
        fecha_fin: endDate,
        id_institucion_educativa: institutionId,
      }
    );
    const responseData = await response.data;
    return responseData.resultado.total_egreso__sum;
  } catch (error) {
    console.error(
      "Error al obtener total de egreso al mes por empleado",
      error
    );
  }
};
export const fetchTotalExpenseBySupplier = async (supplierId, fistDate, endDate,institutionId) => {
  try {
    const response = await api.post("/app-administracion/helpers/get-egresos-proveedor", {
      id_proveedor: supplierId,
      fecha_inicio: fistDate, //this.dateRange.from,
      fecha_fin: endDate, //this.dateRange.to,
      id_institucion_educativa: institutionId//this.institutionId,
    });
    const responseData = await response.data;
    return responseData.resultado.total_egreso__sum;
  } catch (error) {
    console.error(
      "Error al obtener total de egreso al mes por empleado",
      error
    );
  }
};
export const fetchTotalIncomeByMonth = async ({firstDay, lastDay}, institutionId) => {
  try {
    const response = await api.post("/app-administracion/helpers/get-resumen-ingresos-mes", {
      fecha_inicio: firstDay,
      fecha_fin: lastDay,
      id_institucion_educativa: institutionId //this.institutionId,
    });
    const responseData = await response.data;
    return responseData.resultado.total_ingreso__sum;
  } catch (error) {
    console.error("Error al obtener total de ingreso del mes", error);
  }
};
export const fetchTotalExpenseByMonth = async ({firstDay, lastDay}, institutionId) => {
  try {
    const response = await api.post("/app-administracion/helpers/get-resumen-egresos-mes", {
      fecha_inicio: firstDay,
      fecha_fin: lastDay,
      id_institucion_educativa: institutionId//this.institutionId,
    });
    const responseData = await response.data;
    return responseData.resultado.total_egreso__sum;
  } catch (error) {
    console.error("Error al obtener total de egreso del mes", error);
  }
};
export const fetchStatusReport = async (startdate, firstDate, institutionId) => {
  try {
    const response = await api.post("/app-administracion/helpers/get-estado-resultados-mes", {
      fecha_inicio: startdate,//this.dateRange.from,
      fecha_fin: firstDate,//this.dateRange.to,
      id_institucion_educativa: institutionId ///this.institutionId,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.error("Error al obtener estado de resultados", error);
  }
};
export const fetchStudentByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/alumno?institucion_educativa=${institutionId}`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener categorias de egresos por institucion educativa",
      error
    );
  }
};
