import { mapGetters, mapMutations, mapState } from 'vuex';
import KnCardGraph from '../../dashboard/components/KnCardGraph/KnCardGraph.vue';
import { dateUtilsMixin } from '../../shared/mixins/dateUtilsMixin';
import { generalFetchingMixin } from '../../shared/mixins/generalFetchingMixin';
import { fetchEmployeeByEI } from '../../employees/helpers/employeeOptions';
import { fetchSuplierByEI } from '../../suppliers/helpers/suppliersOptions';
import { fetchStudentByEI } from '../helpers/dashboardOptions';
import { getFullName, deleteUndefined } from '../../shared/helpers/dataUtils';

import {
  fetchStatusReport,
  fetchTotalIncomeByStudent,
  fetchTotalExpenseByEmployee,
  fetchTotalExpenseBySupplier,
  fetchTotalIncomeByMonth,
  fetchTotalExpenseByMonth,
} from '../helpers/dashboardOptions';
export default {
  components: { KnCardGraph },
  mixins: [generalFetchingMixin, dateUtilsMixin],
  data() {
    return {
      charts: [],
      statusReportCharts: [],
      students: [],
      employees: [],
      suppliers: [],
      statusReportResults: [],
      dateRange: {
        from: '',
        to: '',
      },
      today: null,
      currentYear: null,
      currentMonth: null,
      lastMonths: [],
      loading: false,
      incomeHeaders: [
        { text: 'Fecha', value: 'fecha_elaboracion', class: 'purple--text' },
        { text: 'Estatus', value: 'estatus.dato', class: 'purple--text' },
        { text: 'Alumno', value: 'nombre', class: 'purple--text' },
        { text: 'Concepto', value: 'concepto', class: 'purple--text' },
        { text: 'Subtotal', value: 'sub_total', class: 'purple--text' },
        { text: 'Impuestos', value: 'total_impuestos', class: 'purple--text' },
        { text: 'Descuento', value: 'total_descuento', class: 'purple--text' },
        {
          text: 'Penalizacion',
          value: 'total_penalizaciones',
          class: 'purple--text',
        },
        { text: 'Total', value: 'total_ingreso', class: 'purple--text' },
        {
          text: 'Forma de pago',
          value: 'forma_de_pago.dato',
          class: 'purple--text',
        },
        {
          text: 'Cuenta',
          value: 'cuenta_destino.nombre_cuenta',
          class: 'purple--text',
        },
        /*{
          text: 'Balance',
          value: 'cuenta_destino.balance',
          class: 'purple--text',
        },*/
      ],
      expenseHeaders: [
        { text: 'Fecha', value: 'fecha_elaboracion', class: 'purple--text' },
        { text: 'Estatus', value: 'estatus.dato', class: 'purple--text' },
        {
          text: 'Nombre o razón social',
          value: 'nombre',
          class: 'purple--text',
        },
        { text: 'Receptor', value: 'receptor', class: 'purple--text' },
        { text: 'Concepto', value: 'concepto', class: 'purple--text' },
        { text: 'Subtotal', value: 'sub_total', class: 'purple--text' },
        { text: 'Impuestos', value: 'total_impuestos', class: 'purple--text' },
        { text: 'Descuento', value: 'total_descuento', class: 'purple--text' },
        { text: 'Total', value: 'total_egreso', class: 'purple--text' },
        {
          text: 'Forma de pago',
          value: 'forma_de_pago.dato',
          class: 'purple--text',
        },
        {
          text: 'Cuenta',
          value: 'cuenta_origen.nombre_cuenta',
          class: 'purple--text',
        },
        /*{
          text: 'Balance',
          value: 'cuenta_origen.balance',
          class: 'purple--text',
        },*/
      ],
      incomeItems: [],
      expenseItems: [],
    };
  },
  async created() {
    this.setIsLogin(false);
    await this.fetchData();
  },
  computed: {
    ...mapState(['institutionId']),
    ...mapGetters(['nameUserLoggedIn']),

    activeEmployee() {
      if (this.employees && this.employees.results) {
        return this.employees.results.filter(
          (category) => category.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    activeSuplier() {
      if (this.suppliers && this.suppliers.results) {
        return this.suppliers.results.filter(
          (supplier) => supplier.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
    activeStudent() {
      if (this.students && this.students.results) {
        return this.students.results.filter(
          (student) => student.estatus_sistema === true
        );
      } else {
        return [];
      }
    },
  },
  watch: {
    institutionId: {
      async handler() {
        await this.fetchData();
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async fetchData() {
      this.loading = true;
      this.charts = [];
      this.statusReportCharts = [];
      this.students = [];
      this.employees = [];
      this.suppliers = [];
      this.statusReportResults = [];
      this.students = await fetchStudentByEI(this.institutionId);
      this.employees = await fetchEmployeeByEI(this.institutionId);
      this.suppliers = await fetchSuplierByEI(this.institutionId);
      this.today = new Date();
      this.currentYear = this.today.getFullYear();
      this.currentMonth = this.today.getMonth();
      this.dateRange.from = this.getFirstDayOfMonth(
        this.currentYear,
        this.currentMonth
      );
      this.dateRange.to = this.getLastDayOfMonth(
        this.currentYear,
        this.currentMonth
      );

      this.statusReportResults = await fetchStatusReport(
        this.dateRange.from,
        this.dateRange.to,
        this.institutionId
      );

      await this.buildChartIncomeByStudents();
      await this.buildChartExpenseByEmployee();
      await this.buildChartExpenseBySupplier();

      this.lastMonths = this.calculateLastMonths(6);
      // console.log('ultimos seis meses:', this.lastMonths);

      await this.buildChartIncomeLastSixMonths();
      await this.buildChartExpenseLastSixMonths();

      this.buildStatusReportCharts();
      this.buildIncomeTable();
      this.buildExpenseTable();

      this.loading = false;
    },
    async filterByDateRange() {
      this.loading = true;
      this.charts = [];
      this.statusReportCharts = [];
      this.statusReportResults = await fetchStatusReport(
        this.dateRange.from,
        this.dateRange.to,
        this.institutionId
      );

      await this.buildChartIncomeByStudents();
      await this.buildChartExpenseByEmployee();
      await this.buildChartExpenseBySupplier();

      await this.buildChartIncomeLastSixMonths();
      await this.buildChartExpenseLastSixMonths();

      this.buildStatusReportCharts();
      this.buildIncomeTable();
      this.buildExpenseTable();
      this.loading = false;
    },
    async buildChartIncomeByStudents() {
      let labels = []; // Nombre de los alumnos
      let data = []; // Montos de ingreso
      let total = 0.0;
      for (const student of this.students.results) {
        const {
          primer_nombre: pn,
          segundo_nombre: sn,
          apellido_paterno: ap,
          apellido_materno: am,
        } = student.datos_personales;
        const fullName = `${pn} ${sn} ${ap} ${am}`;
        labels.push(fullName);
        student.nombre = fullName;
        let mount = 0.0;
        const value = await fetchTotalIncomeByStudent(
          student.id,
          this.dateRange.from,
          this.dateRange.to,
          this.institutionId
        );
        // console.log('Monto: ', value);
        if (value !== undefined && value !== null) {
          mount = parseFloat(value);
        }
        data.push(mount);
      }
      total = data.reduce((a, b) => a + b, 0);
      // console.log('Big mount:', total);

      const dataset = {
        label: 'Monto:',
        data: [...data],
        backgroundColor: 'rgba(19, 111, 238, .9)', // Azul claro - info
        borderColor: '#0047BA', // Azul oscuro - primary
        borderWidth: 3,
        borderRadius: 0,
      };
      const chart = {
        id: 'g1-' + this.institutionId,
        title: `Ingresos por alumno`,
        subtitle: `Periodo: ${this.dateRange.from} - ${this.dateRange.to}`,
        keyGraph: 'ingal',
        colsGraph: total > 0.0 ? 12 : 1,
        showText: true,
        showGraph: total > 0.0,
        bigMount: `+$${total}`,
        chartData: {
          type: 'bar',
          data: {
            labels: [...labels],
            datasets: [{ ...dataset }],
          },
        },
        cols: 12,
        md: total > 0.0 ? 6 : 4,
      };
      // console.log('chart obj', chart);

      this.charts.push(chart);
    },
    async buildChartExpenseByEmployee() {
      let labels = []; // Nombre de los empleados
      let data = []; // Montos de egreso
      let total = 0.0;
      for (const employee of this.employees.results) {
        const {
          primer_nombre: pn,
          segundo_nombre: sn,
          apellido_paterno: ap,
          apellido_materno: am,
        } = employee.datos_personales;
        const fullName = `${pn} ${sn} ${ap} ${am}`;
        labels.push(fullName);
        employee.nombre = fullName;
        let mount = 0.0;
        const value = await fetchTotalExpenseByEmployee(
          employee.id,
          this.dateRange.from,
          this.dateRange.to,
          this.institutionId
        );
        if (value !== undefined && value !== null) {
          mount = parseFloat(value);
        }
        // console.log('Monto: ', mount);
        data.push(mount);
      }
      total = data.reduce((a, b) => a + b, 0);
      // console.log('Big mount:', total);

      const dataset = {
        label: 'Monto',
        data: [...data],
        backgroundColor: 'rgba(255, 182, 0, 1)', // Amarillo - warning
        borderColor: '#ff5f00', // Naranja - error
        borderWidth: 3,
        borderRadius: 0,
      };
      const chart = {
        id: 'g2-' + this.institutionId,
        title: `Egresos por empleado`,
        subtitle: `Periodo: ${this.dateRange.from} - ${this.dateRange.to}`,
        keyGraph: 'egemp',
        colsGraph: total > 0.0 ? 12 : 1,
        showText: true,
        showGraph: total > 0.0,
        bigMount: `-$${total}`,
        chartData: {
          type: 'bar',
          data: {
            labels: [...labels],
            datasets: [{ ...dataset }],
          },
        },
        cols: 12,
        md: total > 0.0 ? 6 : 4,
      };
      // console.log('chart obj', chart);

      this.charts.push(chart);
    },
    async buildChartExpenseBySupplier() {
      let labels = []; // Nombre de los proveedores
      let data = []; // Montos de egreso
      let total = 0.0;
      for (const supplier of this.suppliers.results) {
        const supplierName = supplier.nombre_proveedor; // supplier.datos_fiscales[0].nombre_o_razon_social;
        labels.push(supplierName);
        supplier.nombre = supplierName;
        let mount = 0.0;
        const value = await fetchTotalExpenseBySupplier(
          supplier.id,
          this.dateRange.from,
          this.dateRange.to,
          this.institutionId
        );
        if (value !== undefined && value !== null) {
          mount = parseFloat(value);
        }
        // console.log('Monto: ', mount);
        data.push(mount);
      }
      total = data.reduce((a, b) => a + b, 0);
      // console.log('Big mount:', total);

      const dataset = {
        label: 'Monto',
        data: [...data],
        backgroundColor: 'rgba(255, 114, 118, 1)', // Amarillo - warning
        borderColor: '#F6DFA4', // Naranja - error
        borderWidth: 3,
        borderRadius: 0,
      };
      const chart = {
        id: 'g5-' + this.institutionId,
        title: `Egresos por proveedor`,
        subtitle: `Periodo: ${this.dateRange.from} - ${this.dateRange.to}`,
        keyGraph: 'egprov',
        colsGraph: total > 0.0 ? 12 : 1,
        showText: true,
        showGraph: total > 0.0,
        bigMount: `-$${total}`,
        chartData: {
          type: 'bar',
          data: {
            labels: [...labels],
            datasets: [{ ...dataset }],
          },
        },
        cols: 12,
        md: total > 0.0 ? 6 : 4,
      };
      // console.log('chart obj', chart);

      this.charts.push(chart);
    },
    async buildChartIncomeLastSixMonths() {
      let labels = []; // Nombre de los meses
      let data = []; // Montos de ingreso
      let total = 0.0;
      for (const month of this.lastMonths) {
        labels.push(month.name);
        let mount = 0.0;
        const value = await fetchTotalIncomeByMonth(month, this.institutionId);
        if (value !== undefined && value !== null) {
          mount = parseFloat(value);
        }
        // console.log('Monto: ', mount);
        data.push(mount);
      }
      total = data.reduce((a, b) => a + b, 0);
      // console.log('Big mount:', total);

      const firstMonth = this.lastMonths[0].name;
      const lastMonth = this.lastMonths[this.lastMonths.length - 1].name;
      const dataset = {
        label: 'Ingreso del mes',
        data: [...data],
        backgroundColor: 'rgba(0, 174, 66, .5)', // verde - success
        borderColor: '#00ae42', // verde - success
        borderWidth: 3,
        borderRadius: 0,
      };
      const chart = {
        id: 'g3-' + this.institutionId,
        title: `Ingresos últimos seis meses`,
        subtitle: `Periodo:  ${firstMonth} - ${lastMonth}`,
        keyGraph: 'inmes',
        colsGraph: total > 0.0 ? 12 : 1,
        showText: true,
        showGraph: total > 0.0,
        bigMount: `+$${total}`,
        chartData: {
          type: 'line',
          data: {
            labels: [...labels],
            datasets: [{ ...dataset }],
          },
        },
        cols: 12,
        md: total > 0.0 ? 4 : 4,
      };
      // console.log('chart obj', chart);

      this.charts.push(chart);
    },
    async buildChartExpenseLastSixMonths() {
      let labels = []; // Nombre de los meses
      let data = []; // Montos de egreso
      let total = 0.0;
      for (const month of this.lastMonths) {
        labels.push(month.name);
        let mount = 0.0;
        const value = await fetchTotalExpenseByMonth(month, this.institutionId);
        if (value !== undefined && value !== null) {
          mount = parseFloat(value);
        }
        // console.log('Monto: ', mount);
        data.push(mount);
      }
      total = data.reduce((a, b) => a + b, 0);
      // console.log('Big mount:', total);

      const firstMonth = this.lastMonths[0].name;
      const lastMonth = this.lastMonths[this.lastMonths.length - 1].name;
      const dataset = {
        label: 'Egreso del mes',
        data: [...data],
        backgroundColor: 'rgba(255, 95, 0, .5)', // verde - success
        borderColor: '#ff5f00', // verde - success
        borderWidth: 3,
        borderRadius: 0,
      };
      const chart = {
        id: 'g4-' + this.institutionId,
        title: `Egresos últimos seis meses`,
        subtitle: `Periodo:  ${firstMonth} - ${lastMonth}`,
        keyGraph: 'egmes',
        colsGraph: total > 0.0 ? 12 : 1,
        showText: true,
        showGraph: total > 0.0,
        bigMount: `-$${total}`,
        chartData: {
          type: 'line',
          data: {
            labels: [...labels],
            datasets: [{ ...dataset }],
          },
        },
        cols: 12,
        md: total > 0.0 ? 4 : 4,
      };
      // console.log('chart obj', chart);

      this.charts.push(chart);
    },
    buildStatusReportCharts() {
      // console.log('Resultados de reporte de estatus: ', this.<statusReportResults>);
      const periodText = `Periodo: ${this.dateRange.from} - ${this.dateRange.to}`;
      const chartType = 'doughnut';
      const chartColsMax = 10;
      const {
        total_ingresos_por_categoria,
        total_ingresos_por_cuenta,
        total_ingresos_por_forma_pago,
        total_egresos_por_categoria,
        // total_egresos_por_proveedor,
        total_egresos_por_cuenta,
        total_egresos_por_centro_de_costos,
        total_egresos_forma_pago,
      } = this.statusReportResults;

      /** Gráficas de categorias */
      const incomeCategoryChart = this.statusReportChart(
        total_ingresos_por_categoria,
        'categoria',
        'total_categoria',
        'total_ingreso__sum',
        'g6',
        'insta',
        chartType,
        'Ingresos por categoría',
        periodText,
        '+$',
        1,
        chartColsMax,
        4,
        4
      );

      const expenseCategoryChart = this.statusReportChart(
        total_egresos_por_categoria,
        'categoria',
        'total_categoria',
        'total_egreso__sum',
        'g7',
        'egsta',
        chartType,
        'Egresos por categoría',
        periodText,
        '-$',
        1,
        chartColsMax,
        4,
        4
      );

      /** Gráficas de cuentas bancarias */
      const incomeAccountChart = this.statusReportChart(
        total_ingresos_por_cuenta,
        'cuenta',
        'total_cuenta',
        'total_ingreso__sum',
        'g8',
        'incue',
        chartType,
        'Ingresos por cuenta bancaria',
        periodText,
        '+$',
        1,
        chartColsMax,
        4,
        4
      );

      const expenseAccountChart = this.statusReportChart(
        total_egresos_por_cuenta,
        'cuenta',
        'total_cuenta',
        'total_egreso__sum',
        'g9',
        'egcue',
        chartType,
        'Egresos por cuenta bancaria',
        periodText,
        '-$',
        1,
        chartColsMax,
        4,
        4
      );

      /** Gráficas de formas de pago */
      const incomePaymentMethodsChart = this.statusReportChart(
        total_ingresos_por_forma_pago,
        'forma',
        'total_forma_pago',
        'total_ingreso__sum',
        'g10',
        'infpa',
        chartType,
        'Ingresos por forma de pago',
        periodText,
        '+$',
        1,
        chartColsMax,
        4,
        4
      );

      const expensePaymentMethodsChart = this.statusReportChart(
        total_egresos_forma_pago,
        'forma',
        'total_forma_pago',
        'total_egreso__sum',
        'g11',
        'egfpa',
        chartType,
        'Egresos por forma de pago',
        periodText,
        '-$',
        1,
        chartColsMax,
        4,
        4
      );

      /** Gráfica de centros de costos */
      const expenseCostCenterChart = this.statusReportChart(
        total_egresos_por_centro_de_costos,
        'centro_de_costos',
        'total_centro',
        'total_egreso__sum',
        'g12',
        'egcco',
        chartType,
        'Egresos por centro de costos',
        periodText,
        '-$',
        1,
        chartColsMax,
        4,
        4
      );

      /** Acomodando graficas
       * Primero todos los ingresos
       * Despues todos los egresos
       */
      this.statusReportCharts.push(incomeCategoryChart);
      this.statusReportCharts.push(incomeAccountChart);
      this.statusReportCharts.push(incomePaymentMethodsChart);
      this.statusReportCharts.push(expenseCategoryChart);
      this.statusReportCharts.push(expenseAccountChart);
      this.statusReportCharts.push(expensePaymentMethodsChart);
      this.statusReportCharts.push(expenseCostCenterChart);
    },
    buildIncomeTable() {
      const { ingresos } = this.statusReportResults;
      ingresos.forEach((income) => {
        const student = this.students.results.find((std) =>
          income.alumno ? std.id === income.alumno.id : false
        );
        const fullName = student
          ? getFullName(student.datos_personales)
          : 'No aplica';
        income.nombre = fullName;
        const concept = `${income.categoria.dato} | ${
          income.adeudo ? income.adeudo.comentarios : ''
        }`;
        if (
          concept !== undefined &&
          concept !== null &&
          concept !== '' &&
          concept !== 'undefined | undefined'
        ) {
          income.concepto = deleteUndefined(concept);
        } else {
          income.concepto = 'No aplica';
        }
        income.total_descuento = income.descuento_aplicado
          ? income.total_descuento
          : '0.00';
        income.total_penalizaciones = income.penalizacion_aplicada
          ? income.total_penalizaciones
          : '0.00';
      });
      this.incomeItems = [...ingresos];
    },
    buildExpenseTable() {
      const { egresos } = this.statusReportResults;
      let person = null;
      egresos.filter((expense) => {
        const receiver = expense.proveedor
          ? 'Proveedor'
          : expense.empleado
          ? 'Empleado'
          : 'No aplica';
        expense.receptor = receiver;
        switch (receiver) {
          case 'Proveedor':
            person = this.suppliers.results.find(
              (supplier) => supplier.id === expense.proveedor.id
            );
            expense.nombre = person ? person.nombre : 'No aplica';
            // console.log('Egresos: ', expense);
            break;
          case 'Empleado':
            person = this.employees.results.find(
              (employee) => employee.id === expense.empleado.id
            );
            expense.nombre = person ? person.nombre : 'No aplica';
            break;
          default:
            expense.nombre = 'No aplica';
            break;
        }
        const concept = `${expense.categoria.dato} - ${expense.sub_categoria.dato}`;
        if (
          concept !== undefined &&
          concept !== null &&
          concept !== '' &&
          concept !== 'undefined | undefined'
        ) {
          expense.concepto = deleteUndefined(concept);
        } else {
          expense.concepto = 'No aplica';
        }
      });
      this.expenseItems = [...egresos];
    },
    calculateLastMonths(nMonths) {
      let lastNMonths = [];

      for (let i = nMonths; i >= 0; i--) {
        const from = this.getFirstDayOfMonth(
          this.currentYear,
          this.currentMonth - i
        );
        const to = this.getLastDayOfMonth(
          this.currentYear,
          this.currentMonth - i
        );
        const toDate = new Date(to);
        const toMonth = toDate.getMonth();
        const monthName = this.months[toMonth];
        let month = {
          name: monthName,
          firstDay: from,
          lastDay: to,
        };
        lastNMonths.push(month);
      }

      return lastNMonths;
    },
    generateRandomColor() {
      const randomValue = this.getRandomHexNumber(16777215);
      const randomValueLength = randomValue.length;
      const spaces = 6 - randomValueLength;
      let valuesForComplete = '';
      for (let i = 0; i < spaces; i++) {
        const value = this.getRandomHexNumber(15);
        valuesForComplete += value;
      }
      return `#${randomValue}${valuesForComplete}b2`;
    },
    getRandomHexNumber(limit) {
      return Math.floor(Math.random() * limit).toString(16);
    },
    configChart(
      canvasId,
      chartKey,
      chartType,
      chartTitle,
      chartSubtitle,
      labels,
      dataset,
      total,
      prefixBigMount = '$',
      chartColsMin = 1,
      chartColsMax = 12,
      cardMdMin = 4,
      cardMdMax = 6,
      cardColsMax = 12
    ) {
      const chart = {
        id: canvasId,
        title: chartTitle,
        subtitle: chartSubtitle,
        keyGraph: chartKey,
        colsGraph: total > 0.0 ? chartColsMax : chartColsMin,
        showText: true,
        showGraph: total > 0.0,
        bigMount: `${prefixBigMount}${total}`,
        chartData: {
          type: chartType,
          data: {
            labels: [...labels],
            datasets: [{ ...dataset }],
          },
        },
        cols: cardColsMax,
        md: total > 0.0 ? cardMdMax : cardMdMin,
      };
      return chart;
    },
    getLabelsArray(array, labelKey) {
      return array.map((item) => item[labelKey]);
    },
    getTotalsArray(array, fatherLabelKey, childLabelKey = null) {
      return array.map((item) =>
        childLabelKey !== null
          ? parseFloat(item[fatherLabelKey][childLabelKey])
          : parseFloat(item[fatherLabelKey])
      );
    },
    getTotalAmount(array) {
      return array.reduce((a, b) => a + b, 0);
    },
    getColorArray(arrayLength) {
      const colorArray = [];
      for (let i = 0; i < arrayLength; i++) {
        colorArray.push(this.generateRandomColor());
      }
      return colorArray;
    },
    statusReportChart(
      array,
      labelKey,
      containerAmountKey,
      sumAmountKey,
      canvasId,
      chartKey,
      chartType,
      chartTitle,
      chartSubtitle,
      prefixBigMount = '$',
      chartColsMin = 1,
      chartColsMax = 12,
      cardMdMin = 4,
      cardMdMax = 6,
      cardColsMax = 12
    ) {
      const labels = this.getLabelsArray(array, labelKey);
      const totals = this.getTotalsArray(
        array,
        containerAmountKey,
        sumAmountKey
      );
      const total = this.getTotalAmount(totals);
      const colors = this.getColorArray(labels.length);
      // console.log('colors de ', labelKey, colors);

      const dataset = {
        label: chartTitle,
        data: [...totals],
        backgroundColor: [...colors],
      };
      const chartObj = this.configChart(
        canvasId,
        chartKey,
        chartType,
        chartTitle,
        chartSubtitle,
        labels,
        dataset,
        total,
        prefixBigMount,
        chartColsMin,
        chartColsMax,
        cardMdMin,
        cardMdMax,
        cardColsMax
      );
      return chartObj;
    },
  },
};
