import { api } from "@/api/axios-base";
import { getItem, postItem, putItem, deleteItem } from "@/api/api-methods";

export const fetchSuppliers = async ({
  institutionId = null,
  userId = null,
  personalDataId = null,
  taxDataId = null,
  categoriesIds = null,
  subCategoriesIds = null,
  supplierName = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const userIdFilter = userId ? `&usuario=${userId}` : "";
    const personalDataIdFilter =
      personalDataId !== null ? `&datos_personales=${personalDataId}` : "";
    const taxDataIdFilter =
      taxDataId !== null ? `&datos_fiscales=${taxDataId}` : "";
    const categoriesFilter =
      categoriesIds !== null ? `&categorias=${categoriesIds}` : "";
    const subCategoriesFilter =
      subCategoriesIds !== null ? `&sub_categorias=${subCategoriesIds}` : "";
    const supplierNameFilter =
      supplierName !== null ? `&nombre_proveedor=${supplierName}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-personas/filters/proveedor?".concat(
        institutionFilter,
        userIdFilter,
        personalDataIdFilter,
        taxDataIdFilter,
        categoriesFilter,
        subCategoriesFilter,
        supplierNameFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los proveedores. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener proveedores. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener proveedores. " + error,
      count: 0,
    };
  }
};

export const postSupplier = async (supplier) => {
  try {
    const response = await postItem("/app-personas/proveedor", supplier);
    if (response.e) {
      return {
        ok: false,
        message: "No se pudo crear el proveedor. " + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: "Error al intentar crear proveedor. " + error,
    };
  }
};

export const putSupplier = async (supplier) => {
  try {
    const { id } = supplier;
    const response = await putItem(`/app-personas/proveedor/${id}`, supplier);
    if (response.e) {
      return {
        ok: false,
        message: "No se pudo actualizar el proveedor. " + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: "Error al intentar actualizar proveedor. " + error,
    };
  }
};

export const delSupplier = async (id) => {
  try {
    const response = await deleteItem("/app-personas/proveedor", id);
    if (response.e) {
      return {
        ok: false,
        message: "No se pudo eliminar el proveedor. " + response.e,
      };
    }
    return { ok: true, data: "Exito al eliminar proveedor" };
  } catch (error) {
    return {
      ok: false,
      message: "Error al intentar eliminar proveedor. " + error,
    };
  }
};

export const fetchSuplierByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/proveedor?institucion_educativa=${institutionId}`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      "Error al intentar obtener categorias de egresos por institucion educativa",
      error
    );
  }
};
export const deleteSupplier = async (supplierId) => {
  try {
    const { data } = await api.delete(`/app-personas/proveedor/${supplierId}`);
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
/**
 * Reactiva un proveedor
 * @param {number} supplierId Id del proveedor a reactivar
 */
export const reactivateSupplier = async (supplierId) => {
  try {
    const response = await postItem(
      "/app-personas/helpers/reactivar-proveedor",
      {
        id_proveedor: supplierId,
      }
    );
    if (response.e) {
      return {
        ok: false,
        message: "No se pudo reactivar el proveedor. " + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const fetchSupplierCategories = async ({
  institutionId = null,
  data = null,
  systemStatus = null,
  limit = 100,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const dataFilter = data !== null ? `&dato=${data}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-personas/filters/categoria-proveedor?".concat(
        institutionFilter,
        dataFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener las categorias de proveedor. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener categorias de proveedor. ", error);
    return {
      ok: false,
      message: "Error al intentar obtener categorias de proveedor. " + error,
      count: 0,
    };
  }
};

export const fetchSupplierCategory = async (categoryId) => {
  try {
    const response = await getItem(
      `/app-personas/categoria-proveedor/${categoryId}`
    );
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e
          ? e
          : detail
          ? detail
          : "Error al obtener categoria de proveedor por id",
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message:
        "Error al intentar obtener categoria de proveedor por id. " + error,
    };
  }
};
export const fetchSupplierSubcategories = async ({
  institutionId = null,
  data = null,
  categoryId = null,
  systemStatus = null,
  limit = 100,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const dataFilter = data !== null ? `&dato=${data}` : "";
    const categoryIdFilter =
      categoryId !== null ? `&categoria=${categoryId}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-personas/filters/sub-categoria-proveedor?".concat(
        institutionFilter,
        dataFilter,
        categoryIdFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener las sub categorias de proveedor. " + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener sub categorias de proveedor. ", error);
    return {
      ok: false,
      message:
        "Error al intentar obtener sub categorias de proveedor. " + error,
      count: 0,
    };
  }
};

export const fetchSupplierSubcategory = async (subcategoryId) => {
  try {
    const response = await getItem(
      `/app-personas/sub-categoria-proveedor/${subcategoryId}`
    );
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e
          ? e
          : detail
          ? detail
          : "Error al obtener sub categoria de proveedor por id",
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message:
        "Error al intentar obtener sub categoria de proveedor por id. " + error,
    };
  }
};
